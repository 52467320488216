<template>
  <div class="academic-years">
    <lenon-list-page
        title="Terminal Reports"
        :columns="columns"
        :rows="results"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        :show-profile-photo="true"
        search-placeholder="Search"
        enable-multi-select
        @onRowSelect="handleRowSelect"
    >
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
            style="margin-bottom: -20px;"
        >
          <lenon-select
              v-model="selectedAcademicYearId"
              placeholder="Academic Year"
              :options="academicYears"
              label-name="name"
              value-name="id"
              class="mr-1"
          />
          <lenon-select
              v-model="selectedTermId"
              placeholder="Select Term"
              :options="filteredTerms"
              label-name="name"
              value-name="ay_term_id"
              class="mr-1"
          />
          <lenon-select
              v-model="selectedClassId"
              placeholder="Select Class"
              :options="classes"
              label-name="name"
              value-name="id"
              class="mr-1"
          />

          <!--          <lenon-button-->
          <!--            icon="ListIcon"-->
          <!--            :icon-only="true"-->
          <!--            variant="outline-primary"-->
          <!--            tool-tip-text="Generate Student Transcripts"-->
          <!--            class="mr-1"-->
          <!--            @onClick="showReportCardModal()"-->
          <!--          />-->
          <b-form-checkbox
              v-model="approved"
              v-b-tooltip.hover="approved?'Disapprove, this prevents parents from viewing results':'Approve, this allows parents to view results'"
              class="custom-control-success"
              switch
              style="margin-top: 8px"
              @change="approveResults"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckCircleIcon"/>
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon"/>
            </span>
          </b-form-checkbox>
          <lenon-button
              icon="MessageCircleIcon"
              variant="outline-primary"
              tool-tip-text="Send results to parents via sms"
              class="mr-1"
              label="Notify"
              @onClick="showReportCardModal()"
          />
          <lenon-button
              icon="PrinterIcon"
              label="Report"
              tool-tip-text="Generate Report Cards"
              @onClick="showReportCardModal(true)"
          />
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-button
              v-b-toggle.report
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="col-12">
          <b-collapse
              id="report"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                    v-model="selectedAcademicYearId"
                    placeholder="Academic Year"
                    :options="academicYears"
                    label-name="name"
                    value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                    v-model="selectedTermId"
                    placeholder="Select Term"
                    :options="filteredTerms"
                    label-name="name"
                    value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                    v-model="selectedClassId"
                    placeholder="Select Class"
                    :options="classes"
                    label-name="name"
                    value-name="id"
                />
              </div>
              <div class="col-md-6">
                <b-form-checkbox
                    v-model="approved"
                    class="custom-control-success mb-1"
                    switch
                    @change="approveResults"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckCircleIcon"/>
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon"/>
                  </span>
                </b-form-checkbox>
              </div>
              <div class="col-md-6 float-right mb-1">
                <lenon-button
                    icon="MessageCircleIcon"
                    variant="outline-primary"
                    tool-tip-text="Send results to parents via sms"
                    class="mr-1"
                    label="Notify"
                    @onClick="showReportCardModal()"
                />
              </div>
              <div class="col-md-6 float-right mb-1">
                <lenon-button
                    variant="outline-primary"
                    icon="PrinterIcon"
                    label="Generate Report"
                    tool-tip-text="Generate Report Cards"
                    @onClick="showReportCardModal(true)"
                />
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="col-12 mb-1" v-if="isFreemium">
          <div class="row align-items-center">
            <div class="col-12 text-danger">
              NB: You are currently on freemium, generating report would require you to have enough report credits to
              generate any report card. For unlimited generations, you can <a href="#"
                                                                              @click="$router.push({name:'subscription'})"
            ><b>Upgrade</b></a>.
            </div>
            <div class="col-12">
              <strong>Report Credits:
                <b-badge
                    variant="light-success"
                    style="font-size: 15px;"
                >
                  {{ creditsBalance }}
                </b-badge>
              </strong>
              <lenon-button @onClick="showCreditsModal" variant="flat-primary" label="Buy Credits"
                            tool-tip-text="Buy credits to generate report cards" icon="PlusIcon"
              />
            </div>
          </div>
        </div>
      </template>
      <template #full_name="{row}">
        {{ row.item.last_name }} {{ row.item.first_name }} {{ row.item.middle_name || '' }}
      </template>
      <template #status="{row}">
        <lenon-button tool-tip-text="Student's results has been withheld, click to make it available" v-if="row.item.withheld" :icon="null" variant="light-danger" @onClick="changeStatus(false,row.item)">
          <b-badge variant="light-danger">
            WITHHELD
          </b-badge>
        </lenon-button>
        <lenon-button tool-tip-text="Student's results is available, click to withhold it" v-else variant="light-success" @onClick="changeStatus(true,row.item)">
          <b-badge variant="light-success">
            AVAILABLE
          </b-badge>
        </lenon-button>

      </template>
      <template #actions="{row}">
        <lenon-button
            icon-only
            variant="flat-primary"
            :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
            @onClick="row.toggleDetails"
        />
      </template>
      <template #row-details="{row}">
        <b-card v-if="row.item.results.length>0">
          <table style="width:100%; margin-bottom: 20px;border: 1px dashed black">
            <tr style="text-align:left;">
              <th>SUBJECT</th>
              <th>C.S</th>
              <th>E.S</th>
              <th>T.S</th>
              <th>H.S</th>
              <th>POSITION</th>
              <th>GRADE</th>
              <th>REMARK</th>
            </tr>
            <tr
                v-for="(result,index) in row.item.results"
                :key="index"
            >
              <td>{{ result.subject }}</td>
              <td>{{ result.class_score }}</td>
              <td>{{ result.exam_score }}</td>
              <td>{{ result.total_score }}</td>
              <td>{{ result.max_score }}</td>
              <td>{{ result.position }}</td>
              <td>{{ result.grade }}</td>
              <td>{{ result.remark }}</td>
            </tr>
          </table>
        </b-card>
      </template>
    </lenon-list-page>
    <validation-observer
        ref="feePaymentForm"
        v-slot="{invalid}"
    >
      <lenon-modal
          :title="generateReportMode?'Generate Report Cards':'Send Results to Parents'"
          :show="smsNotificationModalOpened"
          :show-overlay="sendingNotifications"
          size="md"
          @onClose="smsNotificationModalOpened=false"
      >
        <div class="row mb-1">
          <div
              v-if="generateReportMode"
              class="col-12"
          >
            <div class="row">
              <div class="col-12">
                <small><b>Please select vacation, reopening date and classes to generate report cards.</b></small>
              </div>
              <div class="col">
                <lenon-date-picker
                    v-model="vacation_date"
                    label="Vacation Date"
                />
              </div>
              <div class="col">
                <lenon-date-picker
                    v-model="reopening_date"
                    label="Reopening Date"
                />
              </div>
            </div>
            <!--            You can select more classes to generate report cards-->
          </div>
          <div
              v-else
              class="col-12"
          >
            You can select more classes to send results to parents
          </div>
        </div>
        <lenon-multi-select
            v-if="!sendToSelectedStudents"
            v-model="selectedClassesForModal"
            label-name="name"
            value-name="id"
            :options="classes"
            label="Classes"
            rules="required"
        />
        <div v-if="!generateReportMode" class="mt-1">
          <b-form-checkbox
              v-model="sendToSelectedStudents"
              class="custom-control-success mb-1"
          >
            Only Send to Parents of Selected Students ({{ selectedStudents.length }})
          </b-form-checkbox>
        </div>
        <template slot="modal-actions">
          <div class="row float-right">
            <lenon-button
                variant="flat-danger"
                icon="XIcon"
                class="mr-1"
                label="Cancel"
                @onClick="smsNotificationModalOpened=false"
            />
            <lenon-button
                :label="generateReportMode?'Generate':'Send'"
                :disabled="invalid || (sendToSelectedStudents && selectedStudents.length===0)"
                :loading="generateReportMode?generating:sendingNotifications"
                loading-text="Loading..."
                @onClick="generateReportMode?generateReports():sendNotifications()"
            />
          </div>
        </template>
      </lenon-modal>
    </validation-observer>
    <lenon-modal :show-overlay="loading" :ok-disabled="+creditsToPurchase<1" ok-text="Buy" @onOk="buyCredits()"
                 @onClose="()=>{showTopUpModal= false}" :show="showTopUpModal"
                 title="Report Credits"
    >
      <div class="text-danger">
        NB: A single report card costs 1 credit, and each credit costs GHS0.2.
      </div>
      <lenon-input rules="numeric|min_value:1" name="credits" type="number" v-model="creditsToPurchase"
                   label="Total Credits"
      />
      <label>AMOUNT(GHS): <strong>{{ creditsToPurchase * creditPrice | currency }}</strong></label>
    </lenon-modal>
  </div>
</template>

<script>
import {
  BCollapse, VBToggle, VBTooltip, BFormCheckbox, BBadge
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_STUDENTS_RESULTS_Q, SEND_RESULTS_TO_PARENTS_Q, GENERATE_TERMINAL_REPORTS_Q } from '@/graphql/queries'
import { APPROVE_STUDENTS_RESULTS_M } from '@/graphql/mutations'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import { ValidationObserver } from 'vee-validate'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonInput from '@/lenon/components/LenonInput'

export default {
  name: 'Results',
  components: {
    LenonInput,
    LenonDatePicker,
    LenonModal,
    LenonMultiSelect,
    LenonSelect,
    LenonListPage,
    LenonButton,
    ValidationObserver,
    BCollapse,
    BFormCheckbox,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Student Full Name',
        },
        {
          key: 'total_score',
          label: 'Total Score',
          sortable: true,
        },
        {
          key: 'average',
          label: 'Average',
          sortable: true,
        },
        {
          key: 'cum_average',
          label: 'Cum Average',
          // sortable: true,
        },
        {
          key: 'aggregate',
          label: 'Aggregate',
          // sortable: true,
        },
        {
          key: 'overall_position',
          label: 'Position',
          // sortable: true,
        },
        {
          key: 'status',
          label: 'Results Status',
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ],
      loading: false,
      showTopUpModal: null,
      creditsToPurchase: null,
      creditPrice: 0.2,
      generateReportMode: false,
      sendingNotifications: false,
      smsNotificationModalOpened: false,
      tableLoading: false,
      selectedClassesForModal: [],
      selectedStudents: [],
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      generating: false,
      vacation_date: null,
      reopening_date: null,
      approved: false,
      sendToSelectedStudents: false,
      creditsBalance: 0,
    }
  },
  computed: {
    isFreemium() {
      return this.$store.getters['auth/subscription']?.freemium
    },
    monitorSettings() {
      return +this.selectedAcademicYearId
          * +this.selectedTermId
          * +this.selectedClassId
    },
    previousSetting() {
      return this.$store.getters['results/previousSettings']
    },
    results() {
      return this.$store.getters['results/results']
    },
    classes() {
      if (!this.selectedTerm) {
        return []
      }
      const classes = this.$store.getters['termsClasses/classes']
      if (this.selectedTerm.tag_id) {
        return classes.filter(c => this.selectedTerm.classes.includes(c.id))
      }
      return classes
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    monitorSettings() {
      if (this.selectedTermId && this.selectedClassId && this.selectedAcademicYearId) {
        this.$store.commit('results/setSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
          selectedClassId: this.selectedClassId,
        })
        this.fetchResults()
      } else {
        this.$store.commit('results/setResults', {
          general_information: {},
          students_results: []
        })
      }
    },
  },
  mounted() {
    this.setSettings()
    this.getCreditBalance()
    this.$store.commit('results/setResults', {
      general_information: {},
      students_results: []
    })
  },
  methods: {
    changeStatus(withheld, student) {
      this.tableLoading = true
      this.$http.post('academics/change-results-status', {
        withheld,
        id: student.oid,
        ay_id: this.selectedAcademicYearId,
        term_id: this.selectedTerm.id,
        class_id: this.selectedClassId
      })
          .then((res) => {
            student.withheld = withheld
            this.showSuccess('Updated successfully')
          })
          .catch(() => {
            this.showError('Failed to update student results')
          })
          .finally(() => {
            this.tableLoading = false
          })
    },
    buyCredits() {
      this.loading = true
      this.$http.post('academics/report-credits', { credits: this.creditsToPurchase })
          .then((res) => {
            const link = document.createElement('a')
            link.href = res.data
            link.click()
          })
          .catch((err) => {
            this.showError('Credits Purchase Failed')
          })
          .finally(() => {
            this.loading = false
          })
    },
    showCreditsModal() {
      this.showTopUpModal = true
    },
    getCreditBalance() {
      if (this.isFreemium) {
        this.$http.get('academics/report-credits')
            .then((res) => {
              this.creditsBalance = res.data
            })
      }
    },
    handleRowSelect(items) {
      this.selectedStudents = items
    },
    approveResults(status) {
      if (this.monitorSettings) {
        this.tableLoading = true
        this.$apollo.mutate({
          mutation: APPROVE_STUDENTS_RESULTS_M,
          variables: {
            input: {
              academic_year: this.selectedAcademicYearId,
              class: this.selectedClassId,
              term: this.selectedTerm.id,
              status,
            },
          },
        })
            .then(res => {
              this.showSuccess(this.approved ? 'Approved successfully' : 'Disapproved successfully')
            })
            .catch(err => {
              this.showError('Something went wrong, please try again')
              this.approved = !this.approved
            })
            .finally(() => {
              this.tableLoading = false
            })
      } else {
        this.showInfo('Please make sure the required options are selected.')
        this.approved = !this.approved
      }
    },
    sendNotifications() {
      this.sendingNotifications = true
      this.$apollo.query({
        query: SEND_RESULTS_TO_PARENTS_Q,
        variables: {
          input: {
            academic_year: this.selectedAcademicYearId,
            term: this.selectedTerm.id,
            student_ids: this.sendToSelectedStudents ? this.selectedStudents.map(st => st.oid) : null,
            classes: this.sendToSelectedStudents ? [this.selectedClassId] : this.selectedClassesForModal.map(cl => cl.id),
          },
        },
      })
          .then(res => {
            this.sendingNotifications = false
            if (res.errors) {
              this.showError('Something went wrong, please try again')
            }
            this.showSuccess('Notifications are being sent')
          })
          .catch(err => {
            this.sendingNotifications = false
            this.showError('Something went wrong, please try again')
          })
    },
    generateReports() {
      this.generating = true
      this.$apollo.query({
        query: GENERATE_TERMINAL_REPORTS_Q,
        variables: {
          input: {
            vacation_date: this.vacation_date,
            reopening_date: this.reopening_date,
            academic_year: this.selectedAcademicYearId,
            term: this.selectedTerm.id,
            classes: this.selectedClassesForModal.map(cl => cl.id),
            student_ids: this.selectedStudents.map(st => st.oid),
          },
        },
      })
          .then(res => {
            this.generating = false
            if (res.errors) {
              this.showError('Something went wrong, please try again')
            }
            if (res.data.generateStudentsReport?.length < 1) {
              this.showError('Reports were  not generated')
            }
            res.data.generateStudentsReport?.forEach(report => {
              this.downloadFile(report.path, report.name)
            })
            this.getCreditBalance()
          })
          .catch(err => {
            this.generating = false
            this.showError('Something went wrong, please try again')
          })
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    showReportCardModal(reportMode = false) {
      if (this.monitorSettings) {
        if (this.results.length) {
          this.smsNotificationModalOpened = true
          this.generateReportMode = reportMode
          this.selectedClassesForModal = [this.classes.find(cl => cl.id === this.selectedClassId)]
        } else {
          this.showInfo('There are no results available.')
        }
      } else {
        this.showInfo('Please make sure the required options are selected.')
      }
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
      this.selectedClassId = this.previousSetting?.selectedClassId
    },
    fetchResults() {
      if (this.selectedTerm) {
        this.tableLoading = true
        this.$apollo.query({
          query: FETCH_STUDENTS_RESULTS_Q,
          variables: {
            input: {
              academic_year: this.selectedAcademicYearId,
              class: this.selectedClassId,
              term: this.selectedTerm.id,
            },
          },
        })
            .then(res => {
              this.tableLoading = false
              if (!res.errors) {
                this.$store.commit('results/setResults', res.data.studentsResults)
                this.approved = res.data.approvalStatus
              }
            })
            .catch(err => {
              this.tableLoading = false
              this.showError('Something went wrong, please try again')
            })
      }
    },
  },
}
</script>
